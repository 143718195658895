<template>
  <template v-if="!accessToken">
    <main>
      <section class="login_please">
        <p>원활한 이용을 위해서</p>
        <p><span> 로그인</span>을 해주시기 바랍니다.</p>
        <button @click="router.push('/login')">로그인 바로가기</button>
      </section>
      <section class="ms_introduce">
        <img src="@/assets/images/ms_card.png" alt="멤버십카드이미지"/>
        <p class="ms_title">더파르나스 멤버십 <span>THE PARNAS MEMBERSHIP </span></p>
        <p>
          더파르나스는 그랜드 인터컨티넨탈 서울 파르나스와 파르나스 호텔 제주를 즐겨 찾으시는 고객님들께 객실과 레스토랑 할인 및 그 외 다양한 혜택을
          제공하는 프리미엄 멤버십입니다. 가입과 동시에
          사용하실 수 있는 호텔 숙박권과 레스토랑 이용권 등 다양한 혜택이 모바일 어플리케이션을 통해 제공됩니다.
        </p>
        <p>
          ※ 인터컨티넨탈 서울 코엑스는 2024년 7월 1일부로 영업을 종료하고, 약 1년 간의 전관 리모델링 공사를 거쳐 2025년 9월경 더욱 향상된 시설과 서비스를 갖춘 글로벌 리딩 브랜드의 새로운 호텔로 고객님을 찾아 뵐 예정입니다.
        </p>
      </section>
      <section class="ms_inquire">
        <p>멤버십 문의</p>
        <dl>
          <dt>전화</dt>
          <dd>02-559-7645 (더파르나스팀)</dd>
        </dl>
        <dl>
          <dt>Email</dt>
          <dd>theparnas@parnas.co.kr</dd>
        </dl>
      </section>
    </main>
  </template>
  <template v-else>
    <main>
      <ul class="main_tab">
        <li
            v-for="{name, num} in tabList"
            :key="num"
            :class="{
            'on':currentTabNum === num,
            'off': currentTabNum !== num
          }"
            @click="changeTab({num: num, membershipId: null})"
            v-text="name">
        </li>
      </ul>
    </main>
    <MyMembership v-if="currentTabNum === 1" @changeTab="changeTab"/>
    <RestaurantHistory v-if="currentTabNum === 2"/>
    <Transition>
      <MembershipBenefit :initIndex="initIndex" v-if="currentTabNum === 3"
                         :membershipBusinessList="membershipBusinessList"/>
    </Transition>
    <div id="myModal1" class="modal-custom modal-custom-act" v-if="pushTermsAgree === null">
      <!-- Modal content -->
      <div class="modal-content3">
        <div class="modal-body4">
          <h1>프로모션/이벤트 푸시 알림 동의</h1>
          <p>프로모션/이벤트 푸시알림 수신에 동의하시겠습니까? <br/>알림 수신에 동의하시면 [프로모션/이벤트 알림 수신]이 동의로 변경됩니다.
            <br><br>
            <span style="font-size: smaller;">※ '프로필 수정' 에서 동의여부를 변경할 수 있습니다.</span>
          </p>
          <div class="modal_btn_area">
            <button class="close-modal close-modal3" @click="patchPushAgree(false)">취소</button>
            <button class="close-modal close-modal4" @click="patchPushAgree(true)">동의</button>
          </div>
        </div>
      </div>
    </div>

  </template>
  <div id="myModal1" class="modal-custom modal-custom-act" v-if="noticePopups.length > 0">
    <div v-for="(item) in noticePopups" :key="item.id">
      <div class="modal-content5">
        <div class="modal-body5">
          <img style="width: 100%;"
               @click="noticeImageClick(item.param, item.popupType)"
               :src="`${resourcePath}/files/display/${item['attachFile']['id']}`" alt="">
          <div class="chk_close">
            <input type="checkbox" v-model="checkedNotices" id="chk_top"/>
            <label for="chk_top"><span>7일간 보지 않기</span></label></div>
          <div class="modal_btn_area2">
            <button class="close-modal close-modal5" @click="closeNotice(item.id)">
              <img src="@/assets/images/btn_closeX.png" alt="">
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @root-page("/")
 */
import MyMembership from "@/views/main/MyMembership";
import MembershipBenefit from "@/views/main/MembershipBenefit";
import {mapGetters, mapActions, mapMutations, useStore} from "vuex";
import RestaurantHistory from "@/views/main/RestaurantHistory";
import api from "@/api/modules/main"
import authApi from "@/api/modules/auth"
import {initUserData} from "@/util";
import {useRouter} from "vue-router";
import dayjs from "dayjs";

export default {
  name: "MainVue",
  components: {
    RestaurantHistory,
    MyMembership,
    MembershipBenefit,
  },
  computed: {
    resourcePath() {
      return process.env.VUE_APP_API_URL
    },
    ...mapGetters({
      accessToken: 'getAccessToken', //토큰
      autoLogin: 'getIsAutoLogin', // 자동 로그인 체크 여부
      membership: 'getUserMembershipInfo', //멤버십 정보
      memberId: 'getMemberId', //멤버 seq
      memberName: "getMemberName", //유저 이름
      membershipTermsAgree: "getMembershipTermsAgree", //멤버십 동의 여부
      banners: "getBannerPosition", //베너 목록
      pushTermsAgree: "getPushTermsAgree", //푸시 알림 동의 여부
      phoneAuthCheck: "getPhoneAuthCheck", //핸드폰 필수 인증 동의여부
      relationSwiperMatchObj: "getRelationObjForSwiper", // 멤버십 혜택 스와이퍼 매칭 관계 객체,
      noticePopups: "getNoticePopups" //공지사항 팝업 리스트
    }),
    //공지사항 팝업 리스트
    // noticePopups() {
    //   return localStorage.getItem('notices')
    // }
  },
  async created() {
    try {
      //메인으로 가기 전 핸드폰 미 인증 계정일 시, 핸드폰 인증 화면으로 팅김
      // if (!this.phoneAuthCheck) {
      //   this.router.replace('/phone-auth')
      // }
      //fcm 토큰 저장을 위한 메소드 저장
      window.afterLoginProcess = this.afterLoginProcess

      //메뉴가 열려 있을 시를 대비
      this.setMenuClose()

      //자동 로그인 체크 여부
      if (this.loginCheck()) {
        // 자동로그인 on

        //데이터 셋팅
        await this.getInfoToStartApp()

        //유저 이름 셋팅
        await this.getUserInfo()

        //멤버십 가입 후, 멤버십 약관에 동의한 적 없으면 동의화면으로 튕김
        if (Array.isArray(this.membership) && this.membership.length > 0) {
          this.membership.forEach((item) => {
            if (!item.membershipTermsAgree) {
              this.router.replace({
                name: "MembershipTerms",
                query: {
                  id: item.id
                }
              })
            }
          })
        }
      }
    } catch (e) {
      console.log(e)
    } finally {
      //공지사항 팝업 리스트
      await this.getNoticePopups()
    }
  },
  data() {
    return {
      router: useRouter(),
      store: useStore(),
      currentTabNum: 1,
      prevRoute: "",
      tabList: [
        {name: "나의 멤버십", num: 1},
        {name: "레스토랑 할인내역", num: 2},
        {name: "멤버십 혜택", num: 3},
      ],
      initIndex: 0,
      initMembershipName: '',
      isPushModalOpen: false,
      isMobile: {
        Android: function () {
          return navigator.userAgent.match(/Android/i) != null;
        },
        iOS: function () {
          return navigator.userAgent.match(/iPhone|iPad|iPod/i) != null;
        }
      },
      membershipBusinessList: [],
      checkedNotices: false,
    }
  },
  watch: {
    currentTabNum() {
      if (this.currentTabNum === 1) {
        this.getMyMembershipInfo({available: true, withdrawal: false})
      }
    }
  },
  methods: {
    ...mapMutations([
      "resetAuth",
      "resetMember",
      "setMemberName",
      "resetCouponStates",
      'setMenuClose',
      'setAccessToken',
      'setPushTermsAgree',
      'setNoticePopups'
    ]),
    ...mapActions([
      "getMyMembershipInfo",
      'getNoticePopups'
    ]),
    afterLoginProcess(fcmToken) {
      //fcmToken 저장
      authApi.saveFcmToken(fcmToken)
          .catch(e => {
            console.log(e)
          })
    },
    async getInfoToStartApp() {
      try {
        //디비 cms 멤버십 sync
        api.syncMembership()
            .catch(e => {
              console.log(e)
            })

        //멤버십 정보
        if (this.router.options.history.state.back !== "/login") {
          await this.getMyMembershipInfo({available: true, withdrawal: false})
        }

        //native 브릿지
        //name: ParnasApp, method: login
        //안드로이드인지, ios 인지
        if (this.isMobile.Android() || this.isMobile.iOS()) {

          if (this.isMobile.Android()) {
            //안드로이드
            if (window.ParnasApp && window.ParnasApp.login) {
              await window.ParnasApp.login(this.memberId)
            }

          } else if (this.isMobile.iOS()) {
            //IOS
            window.webkit.messageHandlers.login.postMessage(this.memberId);
          }

        }
      } catch (e) {
        if (e.response.data.message) alert(e.response.data.message)
      }
    },
    changeTab({num, membershipId}) {
      if (membershipId) {
        this.initIndex = this.relationSwiperMatchObj[`${membershipId}`]
      } else {
        this.initIndex = 0
      }
      this.currentTabNum = num
    },
    loginCheck() {
      if (this.autoLogin) {
        //자동 로그인이 체크 되어 있을 때

        if (!this.accessToken) {
          //accessToken이 없으면 비 로그인 상태로 만들기
          initUserData()
          return false
        }

      } else {
        // 자동 로그인이 체크 되어 있지 않다면
        if (sessionStorage.getItem('isLogin') !== "true") {
          // 앱 실행 후 첫 메인 화면 진입했을 때 비 로그인상태로 만들기
          if (this.router.options.history.state.back === null || this.router.options.history.state.back === '/') {
            initUserData()
            return false
          }
        }
      }
      //모든 조건을 충족한다면 true 반환
      return true
    },
    async patchPushAgree(value) {
      try {
        //푸시 동의 여부 모달창이 열렸을 시, 서버 저장후, 결과값을 pushTermsAgree에 저장해 모달 닫기
        const {data: {pushTermsAgree}} = await api.patchPushAgree({pushTermsAgree: value})
        this.setPushTermsAgree({pushTermsAgree: pushTermsAgree})
      } catch (e) {
        this.setPushTermsAgree({pushTermsAgree: false})
        alert('오류로 인해 반영하지 못했습니다. 동의를 원하시면 프로필 동의에서 수정바랍니다.')
        throw e
      }
    },

    closeNoticeForSevenDays(id) {
      let hiddenNotices = localStorage.getItem('hiddenNotices') ?? '';
      if (hiddenNotices) {
        localStorage.setItem('hiddenNotices', JSON.stringify(
            [
              ...JSON.parse(hiddenNotices),
              {id: id, expiredDate: dayjs().add(7, 'day')}
            ]
        ))
      } else {
        // hiddenNotices
        localStorage.setItem('hiddenNotices', JSON.stringify([
              {id: id, expiredDate: dayjs().add(7, 'day')}
            ]
        ))
      }

    },
    closeNotice(id) {
      if (this.checkedNotices) {
        this.closeNoticeForSevenDays(id)
      }
      this.setNoticePopups({
        data: this.noticePopups.filter((item) => item.id !== id)
      })
      this.checkedNotices = false
    },
    noticeImageClick(param, type) {
      if (!param) return false
      switch (type) {
        case "NOTICE":
          this.router.push(`/notice/${param}`)
          break;
        case "PROMOTION":
          window.open(param)
      }
    },
    async getUserInfo() {
      let {data: {name}} = await authApi.getMemberInfo()
      this.setMemberName({memberName: name})
    }
  },
};
</script>

<style>
.ms_introduce p:nth-of-type(3) {
  padding: 20px;
  line-height: 21px;
  font-size: 13px;
  color: #333;
  text-align: left;
}
</style>
