
// state
const state = {
    loadingGettingPhoneAuthNum: false,
}

// mutations
const mutations = {
    setLoadingGettingPhoneAuthNum: (state, value) => {
        state.loadingGettingPhoneAuthNum = value
    },
    setAuthTimeOut: (state) => {
        setTimeout(() => {
            state.loadingGettingPhoneAuthNum = false
        }, 30000)
    },
}

// getters
const getters = {
    getLoadingGettingPhoneAuthNum(state) {
        return state.loadingGettingPhoneAuthNum
    },
}

export default {
    state,
    mutations,
    getters
}
