import dayjs from "dayjs";
import store from "@/store";

const passwordRegex = /^.*(?=^.{8,16}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[~!@#$%^&*-+?]).*$/;
const emailRegex = /^[\da-zA-Z]([-_.]?[\da-zA-Z])*@[\da-zA-Z]([-_.]?[\da-zA-Z])*.[a-zA-Z]{2,3}$/i;
const phoneRegex = /^\d{3}-\d{3,4}-\d{4}$/;
const swiperSortOrder = {
    0: [4, 5, 32, 33], //스마트
    1: [6, 7, 30, 31], //골드
    2: [10, 11, 22, 28, 29], //플레티넘
    3: [34], //제네시스 스마트80
    4: [35], //제네시스 스마트 A
    5: [36], //제네시스 스마트 B
    6: [37], //신한 법인 골드
    7: [39, 40, 52, 53, 54, 55, 56, 57], //제주 호텔 이후 스마트
    8: [41, 42, 52, 53, 54, 55, 56, 57], //제주 호텔 이후 골드
    9: [43, 44, 52, 53, 54, 55, 56, 57], //제주 호텔 이후 플레티넘
    10: [45], //제주 호텔 이후 제네시스 스마트 80
    11: [46], //제주 호텔 이후 제네시스 스마트 70 A
    12: [47], //제주 호텔 이후 제네시스 스마트 70 B
    13: [48], //제주 호텔 이후 신한 법인 골드
    14: [49], //양양 스마트 A
    15: [50], //양양 스마트 B
    16: [51], //양양 골드
    17: [75, 76, 63, 62, 61, 60, 59, 58 ], //스마트
    18: [74, 73, 63, 62, 61, 60, 59, 58 ], //골드
    19: [72, 71, 63, 62, 61, 60, 59, 58 ], //플래티넘
    20: [70], //제네시스 스마트 80
    21: [69], //제네시스 스마트 A
    22: [68], //제네시스 스마트 B
    23: [67], //신한 법인 골드
    24: [66], //양양 스마트 A
    25: [65], //양양 스마트 B
    26: [64], //양양 스마트 골드
    27: [78], //쟈스민 스마트
    28: [77], //쟈스민 골드
    //-------202406----------
    29: [79], //쟈스민 블루 전용_더파르나스 스마트
    30: [80], //쟈스민 블랙 전용_더파르나스 골드
    31: [81, 82, 94, 95], //스마트, 스마트 갱신, 스마트 식음형, 스마트 식음형 갱신
    32: [83, 84, 96, 97], //골드, 골드 갱신, 골드 식음형, 골드 식음형 갱신
    33: [85, 86, 98, 99], //플래티넘, 플래티넘 갱신, 플래티넘 식음형, 플래티넘 식음형 갱신
    34: [87], //제네시스 스마트 80
    35: [88], //제네시스 스마트 A
    36: [89], //제네시스 스마트 B
    37: [90], //신한 법인 골드
    38: [91], //인스케이프 양양 스마트 A
    39: [92], //인스케이프 양양 스마트 B
    40: [93], //인스케이프 양양
    // ------202408-------
    41: [100], //제네시스 EV G80

}

// /**
//  * 한국어만 입력되도록 체크
//  * @param text:String
//  * @returns Boolean
//  */
// function checkOnlyKorean(text) {
//     return onlyKorean.test(text);
// }
/**
 * 비밀번호 정규식 메소드 8-16자리, 대문자, 소문자, 숫자, 특수문자
 * @param password
 * @returns Boolean
 */
function checkPasswordRule(password) {
    return passwordRegex.test(password);
}

/**
 * 이메일 정규식 메소드
 * @param email
 * @returns {boolean}
 */
function checkEmailRule(email) {
    return emailRegex.test(email);
}

function checkPhoneRule(phoneNumber) {
    return phoneRegex.test(phoneNumber);
}

/**
 * 마스킹 이메일 처리 wl*****@naver.com
 */
function maskingEmail(email) {
    if (email === undefined || email === '') {
        return email;
    }
    let len = email.split('@')[0].length - 3;
    return email.replace(new RegExp('.(?=.{0,' + len + '}@)', 'g'), '*');
}


/**
 * 마지막 글자를 * 처리한다. (ex. 홍길동 → 홍길*)
 * @param strName
 * @returns {*}
 */
function maskingName(strName) {
    if (strName === undefined || strName === '') {
        return strName;
    }
    let pattern = /.$/; // 정규식
    return strName.replace(pattern, "*");
}

/**
 * 날짜형식 YYYY-MM-DD
 */
function makeDateFormatYYYYMMDD(date) {
    return dayjs(date).format(`YYYY-MM-DD`)
}

/**
 * 객체 안에 키값이 있는지 확인
 * @param obj
 * @param key
 * @returns {boolean}
 */
function isKeyExists(obj, key) {
    return obj[key] !== undefined;
}

/**
 * null 체크
 * @param value
 * @returns {boolean}
 */
function isEmpty (value) {
    return value === "" ||
        value === null ||
        value === undefined ||
        (typeof value === "object" && !Object.keys(value).length);
}

async function initUserData() {
    await store.commit("resetMember");
    await store.commit("resetAuth");
    await store.commit("resetCouponStates");
    await store.commit("setMenuClose");
    sessionStorage.setItem('isLogin', 'false')
}

export const makeSwiper = (membershipId) => {
    let result = null
    for(let index in swiperSortOrder) {
        console.log(index);

        if(swiperSortOrder[`${index}`].includes(membershipId)) {
            result = Number(index)
        }
    }

    return result
}

export {
    checkPasswordRule,
    checkEmailRule,
    checkPhoneRule,
    maskingEmail,
    maskingName,
    makeDateFormatYYYYMMDD,
    isKeyExists,
    isEmpty,
    initUserData,
    // checkOnlyKorean
}
