import api from "@/api/modules/auth"

// state
const state = {
    accessToken: null,
    refreshToken: null,
    isAutoLogin: false,
}

// mutations
const mutations = {
    resetAuth: (state) => {
        state.accessToken = null
        state.refreshToken = null
        state.isAutoLogin = false
    },
    setAccessToken: (state, {accessToken}) => {
        state.accessToken = accessToken
    },
    setRefreshToken: (state, {refreshToken}) => {
        state.refreshToken = refreshToken
    },
    setIsAutoLogin: (state, {isAutoLogin}) => {
        state.isAutoLogin = isAutoLogin
    }
}

// actions
const actions = {
    doLogin: async ({commit}, {payload}) => {
        const {
            data: {
                tokenInfo: {
                    accessToken,
                    refreshToken,
                    memberId,
                    memberRole,
                    memberName,
                    phoneAuthCheck,
                    pushTermsAgree,
                },
                // userMembershipInfo,
                status,
            },
        } = await api.signIn(payload);
        commit("setMemberId", {memberId: memberId})
        commit("setMemberName", {memberName: memberName})
        commit("setMemberRole", {memberRole: memberRole})
        commit("setPhoneAuthCheck", {phoneAuthCheck: phoneAuthCheck})
        commit("setPushTermsAgree", {pushTermsAgree: pushTermsAgree})
        return {
            accessToken: accessToken,
            refreshToken: refreshToken,
            status: status,
            memberId: memberId,
        }
    },
    doLogout: async ({commit}) => {
        const res = await api.logout()
        if (res.status === 200) {
            commit("resetAuth")
            commit("resetMember")
            commit("setMenuClose")
        }
    }
}

// getters
const getters = {
    getAccessToken(state) {
        return state.accessToken
    },
    getRefreshToken(state) {
        return state.refreshToken
    },
    getIsAutoLogin(state) {
        return state.isAutoLogin
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}
