import Main from '@/views/Main'
import authRoutes from "@/router/modules/auth";
import mainRoutes from "@/router/modules/main";
import myPageRoutes from "@/router/modules/mypage";
import NotFound from "@/views/NotFound";

const routes = [
    {
        path: "/",
        meta: {
            bodyId: "main",
            layout: "MainLayout"
        },
        component: Main
    },
    ...authRoutes,
    ...mainRoutes,
    ...myPageRoutes,
    {
        path: '/error',
        name: 'ErrorPage',
        meta: {
            layout: "BasicLayout"
        },
        component: NotFound
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        meta: {
            layout: "BasicLayout"
        },
        component: NotFound
    }
]
export default routes
