<template>
  <body :id="$route.meta.bodyId">
  <div id="wrap" @click="eventCapturing($event.target)">
    <HeaderView/>
    <Menu/>
    <slot/>
  </div>
  </body>
</template>

<script>
import HeaderView from "@/components/common/Header";
import Menu from "@/components/common/Menu";
import {mapMutations} from "vuex";

/**
 * @description 헤더와 뼈대가 있는 Main Layout
 */
export default {
  name: "MainLayout",
  components: {Menu, HeaderView},
  created() {
    document.documentElement.classList.remove("overflow-y-hidden");
  },
  methods: {
    ...mapMutations(['setMenuClose']),
    eventCapturing(e) {
      if (e.id === "page_cover") {
        this.setMenuClose()
      }
    }

  }

};
</script>

<style scoped>
#wrap:has(> #menu.open) {
  overflow: hidden;
}
</style>
