import {createWebHistory, createRouter} from 'vue-router';
import routes from './routes'


const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return {top: 0}
        } else if (to.hash) {
            return {
                selector: to.hash,
                offset: {x: 0, y: 0}
            }
        } else {
            return {top: 0}
        }
    },
});

// let previosPath = ''
// router.beforeEach((to, from, next) => {
//     console.log('------------------------------------------------')
//     console.log("to", to)
//     console.log("from", from)
//     console.log("previosPath", previosPath)
//     //1.이전 페이지가 2.이동하려는 페이지와 같을 때,
//     if(previosPath === '/login' && to.path === '/login') {
//         next(from.path)
//     }else {
//         next()
//     }
//     previosPath = from.path
//     console.log(router)
//     console.log('------------------------------------------------')
// })
export default router;
