import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueDaumPostcode from 'vue-daum-postcode'

import "@/assets/css/default.css"
import "@/assets/css/quill.snow.css"
import "@/assets/css/common.css"
import "@vueform/toggle/themes/default.css"
import "@/assets/css/style.css"

const app = createApp(App)
app.use(store).use(router).use(VueDaumPostcode)
app.mount('#app')
