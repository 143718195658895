const myPageRoutes = [
    {
        path: "/my-page",
        meta: {
            bodyId: "mypage",
            layout: "MainLayout",
            title: 'MY PAGE'
        },
        component: () => import("@/views/myPage"),
    },
    {
        path: "/edit-profile",
        meta: {
            bodyId: "edit_profile",
            layout: "MainLayout",
            title: '프로필 수정'
        },
        component: () => import("@/views/myPage/EditProfile"),
    },
    {
        path: "/edit-profile-form",
        meta: {
            bodyId: "edit_profile",
            layout: "MainLayout",
            title: '프로필 수정'
        },
        component: () => import("@/views/myPage/EditProfileForm"),
    },
    {
        path: "/edit-password",
        meta: {
            bodyId: "edit_password",
            layout: "MainLayout",
            title: '비밀번호 변경'
        },
        component: () => import("@/views/myPage/EditPassword"),
    },
    {
        path: "/edit-password-comp",
        meta: {
            bodyId: "edit_password",
            layout: "MainLayout",
            // title: '비밀번호 변경완료'
        },
        component: () => import("@/views/myPage/EditPasswordComplete"),
    },
    {
        path: "/withdraw",
        meta: {
            bodyId: "edit_withdraw",
            layout: "MainLayout",
            title: '탈퇴'
        },
        component: () => import("@/views/myPage/Withdraw"),
    },
    {
        path: "/expire-membership",
        meta: {
            bodyId: "expire_ms",
            layout: "MainLayout",
            title: '만료된 멤버십'
        },
        component: () => import("@/views/myPage/ExpireMembership"),
    },
]

export default myPageRoutes
