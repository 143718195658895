<template>
  <DefaultLayout :layout="$route.meta['layout']">
    <router-view/>
  </DefaultLayout>
</template>
<script>
import DefaultLayout from "@/views/layout/DefaultLayout";
export default {
  name: "App",
  created() {
    //SUSPENSE, TELEPORT
  },
  components: {
    DefaultLayout,
  }
};
</script>
<style>
.overflow-y-hidden {
  overflow: hidden;
}

#app:has(>.modal-custom) {
  overflow: hidden;
}
/* we will explain what these classes do next! */
.v-enter-active {
  transition: opacity 0.4s ease;
}

.v-enter-from {
  opacity: 0.1;
}

/****************************************************************/
/*********************개발자가 수정한 부분***************************/
/****************************************************************/
.login_input_password {
  position: relative;
  height: 40px;
  line-height: 40px;
}

.login_input_password > input {
  position: absolute;
  width: 100%;
  outline: none;
  background: none;
  border: none;
  border-bottom: 1px solid #d6d6d6;
  line-height: 250%;
}

.login_input_password > img {
  position: absolute;
  opacity: 0.7;
  top: 0;
  bottom: 4px;
  right: 0;
  margin: auto 2px;
  height: 18px;
  z-index: 1;
}
.login_input_bot {
  margin-top: 10px;
}

/*********************/
.swiper.mySwiper4 .swiper-button-prev,
.swiper.mySwiper4 .swiper-button-next {
  --swiper-theme-color: #c7c7c7;
  --swiper-navigation-size: 30px;
}

.swiper.mySwiper4 .swiper-button-prev {
  top: 60px !important;
}

.swiper.mySwiper4 .swiper-button-next {
  top: 60px !important;
}

.swiper.mySwiper4 .swiper-button-next:after {
  content: '';
  background: url(@/assets/images/btn_mc_next.png) no-repeat right;
  width: 10px;
  height: 32px;

}

.swiper.mySwiper4 .swiper-button-prev:after {
  content: '';
  background: url(@/assets/images/btn_mc_back.png) no-repeat right;
  width: 10px;
  height: 32px;
  /*font-size: 15px;*/
}

.modal-content{
  height: 70%;
}
.input_line {
  border-bottom: 1px solid #d6d6d6;
}

/****************************************************************/
/*********************개발자가 수정한 부분***************************/
/****************************************************************/
</style>
