import api from "@/api/modules/main"
import {makeDateFormatYYYYMMDD, makeSwiper} from "@/util";
import dayjs from "dayjs";

// state
const state = {
    memberId: "",
    memberRole: "",
    memberName: "",
    phoneAuthCheck: false,
    pushTermsAgree: null,
    membershipTermsAgree: false,
    marketingAgreeDate: false,
    userMembershipInfo: [],
    agreeInfo: {},
    signUpMemberInfo: {},
    isGettingMembershipLoading: false,
    membershipInfoInMenu: {},
    membershipIdListForSwiper: [], //멤버십 혜택을 위한 스와이퍼
    relationObjForSwiper: {}, //멤버십 혜택을 위한 관계형 객체
    noticePopups: [],//메인화면 공지사항 팝업 리스트
}

// mutations
const mutations = {
    resetMember: (state) => {
        state.memberId = ""
        state.memberRole = ""
        state.memberName = ""
        state.pushTermsAgree = null
        state.phoneAuthCheck = false
        state.membershipTermsAgree = false
        state.marketingAgreeDate = false
        state.userMembershipInfo = []
        state.agreeInfo = {}
        state.signUpMemberInfo = {}
        state.isGettingMembershipLoading = false
        state.membershipInfoInMenu = {}
    },
    setMemberId: (state, {memberId}) => {
        state.memberId = memberId
    },
    setMemberRole: (state, {memberRole}) => {
        state.memberRole = memberRole
    },
    setMemberName: (state, {memberName}) => {
        state.memberName = memberName
    },
    setPhoneAuthCheck: (state, {phoneAuthCheck}) => {
        state.phoneAuthCheck = phoneAuthCheck
    },
    setMembershipTermsAgree: (state, {membershipTermsAgree}) => {
        state.membershipTermsAgree = membershipTermsAgree
    },
    setUserMembershipInfo: (state, {userMembershipInfo}) => {
        state.userMembershipInfo = userMembershipInfo
    },
    setAgreeInfo: (state, {agreeInfo}) => {
        state.agreeInfo = agreeInfo
    },
    setSignUpMemberInfo: (state, {signUpMemberInfo}) => {
        state.signUpMemberInfo = signUpMemberInfo
    },
    setIsGettingMembershipLoading: (state, value) => {
        state.isGettingMembershipLoading = value
    },
    setMembershipInfoInMenu: (state, value) => {
        state.membershipInfoInMenu = value
    },
    setPushTermsAgree: (state, {pushTermsAgree}) => {
        state.pushTermsAgree = pushTermsAgree
    },
    setMembershipsForSwiper: (state, {membershipIdListForSwiper}) => {
        state.membershipIdListForSwiper = membershipIdListForSwiper
    },
    setRelationObjForSwiper: (state, {relationObjForSwiper}) => {
        state.relationObjForSwiper = relationObjForSwiper
    },
    setNoticePopups: (state, {data}) => {
        state.noticePopups = data
    },
}

// actions
const actions = {
    getNoticePopups: async ({commit}) => {
        try {
            // get server notice data
            let {data} = await api.getNoticePopups()

            // get local hidden notice data
            let hiddenNotices = localStorage.getItem('hiddenNotices') ?? '';
            if (hiddenNotices) {
                //만료일 지난 감춤항목 삭제
                let remainList =
                    JSON.parse(hiddenNotices)
                        .filter(({expiredDate}) => expiredDate > dayjs().format())

                //로컬스토리지에 재저장
                localStorage.setItem('hiddenNotices', JSON.stringify(remainList))

                //server data 재 가공
                data = data.filter(dataItem =>
                    remainList
                        .map(item => item.id)
                        .includes(dataItem.id) === false
                )
            }

            commit("setNoticePopups", {data: data})

        } catch (e) {
            if (e.response.data.message) alert(e.response.data.message)
        }
    },
    getMemberInfo: async ({commit}) => {
        try {
            const {data: {phoneAuthCheck}} = await api.getMemberInfo()
            commit("setPhoneAuthCheck", {phoneAuthCheck: phoneAuthCheck})
        } catch (e) {
            if (e.response.data.message) alert(e.response.data.message)
        }
    },
    getMyMembershipInfo: async ({commit}, payload) => {
        try {
            commit("setIsGettingMembershipLoading", true)
            let {data} = await api.getMyMembershipInfo(payload)
            if (data.length > 0) {
                commit("setMembershipInfoInMenu", data[0])
                commit("setUserMembershipInfo", {userMembershipInfo: data})

                //멤버십 혜택 스와이퍼 매칭을 위한 로직

                //멤버십 아이디 넣어줌 result: [32, 41]
                let membershipIdListForSwiper = data.map(item => item.membership.id)
                // console.log("멤버십 아이디 넣어줌", membershipIdListForSwiper)

                //관계형 객체 생성 result: {32: 0, 41: 8}
                let relationObjForSwiper = {}
                membershipIdListForSwiper.forEach((membershipId) => {
                    relationObjForSwiper[`${membershipId}`] = makeSwiper(membershipId)
                })
                // console.log("관계형 객체 생성", relationObjForSwiper)

                // result: [0, 8]
                let tempArr = []
                for (let item in relationObjForSwiper) {
                    tempArr.push(Number(relationObjForSwiper[`${item}`]))
                }
                // console.log("tempArr", tempArr)

                //해당 멤버십의 매칭될 스와이퍼 인덱스를 셋팅해주기 위해 순서대로 정렬 및 중복 제거
                //result : [0, 8]
                tempArr = [...new Set(tempArr.sort((a, b) => a - b))]
                // console.log("중복 제거 후 tempArr", tempArr)


                //result : {32: 0, 41: 1}
                tempArr.forEach((sortItem, index) => {
                    for (let item in relationObjForSwiper) {
                        if (relationObjForSwiper[`${item}`] === sortItem) {
                            relationObjForSwiper[`${item}`] = index
                        }
                    }
                })

                // console.log("최종 관계형 객체 relationObjForSwiper", relationObjForSwiper)


                commit("setMembershipsForSwiper", {membershipIdListForSwiper: membershipIdListForSwiper})
                commit("setRelationObjForSwiper", {relationObjForSwiper: relationObjForSwiper})


            } else {
                commit("setMembershipInfoInMenu", {})
                commit("setUserMembershipInfo", {userMembershipInfo: []})
                commit("setMembershipsForSwiper", {membershipIdListForSwiper: []})
            }
            commit("setIsGettingMembershipLoading", false)
        } catch (e) {
            if (e.response.data.message) alert(e.response.data.message)
            throw e
        }
    }
}

// getters
const getters = {
    getMemberId: (state) => {
        return state.memberId
    },
    getMemberName: (state) => {
        return state.memberName
    },
    getPhoneAuthCheck: (state) => {
        return state.phoneAuthCheck
    },
    getMembershipTermsAgree: (state) => {
        return state.membershipTermsAgree
    },
    getUserMembershipInfo: (state) => {
        if (state.userMembershipInfo !== [] || state.userMembershipInfo.length > 0) {
            return state.userMembershipInfo.map(item => {
                return {
                    ...item,
                    membershipIssuedStartAt: makeDateFormatYYYYMMDD(item.membershipIssuedStartAt),
                    membershipIssuedEndAt: makeDateFormatYYYYMMDD(item.endAt)
                }
            })
        } else {
            return []
        }
    },
    getAgreeInfo: (state) => {
        return state.agreeInfo
    },
    getSignUpMemberInfo: (state) => {
        return state.signUpMemberInfo
    },
    getIsGettingMembershipLoading: (state) => {
        return state.isGettingMembershipLoading
    },
    getMembershipInfoInMenu: (state) => {
        return state.membershipInfoInMenu
    },
    getPushTermsAgree: (state) => {
        return state.pushTermsAgree
    },
    getMembershipsForSwiper: (state) => {
        return state.membershipIdListForSwiper
    },
    getRelationObjForSwiper: (state) => {
        return state.relationObjForSwiper
    },
    getNoticePopups: (state) => {
        return state.noticePopups
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}
