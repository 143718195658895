import api from "@/api/modules/board"

// state
const state = {
    bannerPosition: [
        {exposurePosition: "MAIN"},
        {exposurePosition: "MENU"},
        {exposurePosition: "MYPAGE"}
    ],
    mainBanners: [],
    menuBanners: [],
    myPageBanners: []
}

// mutations
const mutations = {
    resetBanners:(state) => {
      state.mainBanners = []
      state.menuBanners = []
      state.myPageBanners = []
    },
    setMainBanners: (state, {content: content}) => {
        state.mainBanners = content
    },
    setMenuBanners: (state, {content: content}) => {
        state.menuBanners = content
    },
    setMyPageBanners: (state, {content: content}) => {
        state.myPageBanners = content
    },
}

// actions
const actions = {
    getBanners: async ({commit}, {payload}) => {
        try {
            const {
                data: {
                    content
                }
            } = await api.getBanners(payload)
            if (payload.exposurePosition === "MAIN") {
                commit("setMainBanners", {content: content})
            } else if (payload.exposurePosition === "MENU") {
                commit("setMenuBanners", {content: content})
            } else {
                commit("setMyPageBanners", {content: content})
            }
        } catch (e) {
            if(e.response.data.message) alert(e.response.data.message)
        }
    }
}

// getters
const getters = {
    getBannerPosition(state) {
        return state.bannerPosition
    },
    getMainBanners() {
        return state.mainBanners
    },
    getMenuBanners(state) {
        return state.menuBanners
    },
    getMyPageBanners(state) {
        return state.myPageBanners
    },
}

export default {
    state,
    mutations,
    actions,
    getters
}
