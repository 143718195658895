<template>
  <div>
    <section class="ms_info" v-if="membershipList.length <= 0">
      <h3><span>THE PARNAS MEMBERSHIP</span></h3>
      <div class="no_mem">
        <p><span>{{ memberName }}</span>님의 더파르나스 멤버십이 미등록 상태입니다.</p>
        <p>영업일 기준 1~2일 이내 사용 등록 후 문자로 안내드립니다.<br>
          <br>
          <span style="font-size: 13px; color: darkgray;">
          문의 사항 <br/>
          더파르나스팀 02-559-7645
          <br>
          theparnas@parnas.co.kr
        </span>
        </p>
      </div>
    </section>
    <template v-if="!isLoading && !isMembershipLoading">
      <section class="ms_info" v-if="membershipList.length > 0">
        <SwiperComponent
            @beforeInit="beforeInit"
            @slideChange="onSlideChange">
          <template #content>
            <swiper-slide v-for="{
                               id,
                               membershipIssuedSeq,
                               membership,
                               membershipIssuedEndAt
                              } in membershipList"
                          :key="id">
              <article class="ms_card"
                       :class="setClassType(membership.name)">
                <div class="top">
                  <div class="name">
                    <span class="ms_mark">{{ membership.name }}</span>
                    <p>{{ memberName }} <span>님</span></p>
                  </div>
                  <button @click="changeTab(3, membership.id)">
                    상세 혜택 보기
                  </button>
                </div>
                <div class="middle">
                  <p class="num">{{ membershipIssuedSeq }}</p>
                  <p class="valid">
                    <span>만료일</span>{{ membershipIssuedEndAt }}
                  </p>
                </div>
                <button @click="showQRCode(membershipIssuedSeq)">
                  MEMBERSHIP QR
                </button>
              </article>
            </swiper-slide>
          </template>
        </SwiperComponent>
      </section>
      <section class="ms_vc">
        <div class="vc_tabs">
          <input id="usable"
                 type="radio"
                 value="usable"
                 name="tab_item"
                 :checked="couponTab === 'usable'"
                 v-model="couponTab"
                 @click="changeCouponTab('usable')"/>
          <label class="vc_tab_item" for="usable">
            미사용 바우처
            <span><span>{{ usableTotalElements }}</span> 매</span>
          </label>
          <input id="all"
                 type="radio"
                 value="all"
                 name="tab_item"
                 :checked="couponTab === 'all'"
                 v-model="couponTab"
                 @click="changeCouponTab('all')"/>
          <label class="vc_tab_item" for="all">
            전체 바우처
            <span><span>{{ allTotalElements }}</span> 매</span>
          </label>
          <div class="tab_content" id="usable_content">
            <ul class="vc_list">
              <li v-for="{coupon, id} in usableList" :key="id">
                <i class="usable">미사용</i>
                <div style="display: inline-block;
                      max-width: 140px;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;">
                  {{ coupon.name }}
                </div>
                <button @click="$router.push({name: 'CouponDetail', query: {id: id}})">
                  사용하기
                </button>
              </li>
            </ul>
            <div v-if="!usableLast"
                 style="text-align: center; padding-top: 10px">
              <button
                  :disabled="isCouponLoading"
                  @click="unUsableCoupons(usablePage + 1)"
                  style="
                border-radius: 4px;
                color: #555555;
                font-size: 12px;
                letter-spacing: -0.04em;
                padding: 4px 10px;">
                {{ isCouponLoading ? '불러오는중..' : '+ 더보기' }}
              </button>
            </div>
          </div>
          <div class="tab_content" id="all_content">
            <ul class="vc_list">
              <li v-for="{useType, usedAt, id, coupon} in allList" :key="id">
                <i :class="{
              'usable': useType === 'NONE',
              'finish': useType !== 'NONE'
            }">
                  {{ useType === 'NONE' ? '미사용' : '사용완료' }}
                </i>
                <div style="display: inline-block;
                      max-width: 140px;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;">
                  {{ coupon.name }}
                </div>
                <button v-if="useType === 'NONE'"
                        @click="$router.push({name: 'CouponDetail', query: {id: id}})"
                        :disabled="useType !== 'NONE'"
                        :class="{'finish': useType !== 'NONE'}"
                >사용하기
                </button>
                <p class="vc_date" v-if="useType !== 'NONE'">
                  {{ usedAt }}
                </p>
              </li>
            </ul>
            <div v-if="!allLast"
                 style="text-align: center; padding-top: 10px">
              <button
                  :disabled="isCouponLoading"
                  @click="allCoupons(allPage + 1)"
                  style="
                border-radius: 4px;
                color: #555555;
                font-size: 12px;
                letter-spacing: -0.04em;
                padding: 4px 10px;">
                {{ isCouponLoading ? '불러오는중..' : '+ 더보기' }}
              </button>
            </div>
          </div>
        </div>
      </section>
      <section class="ms_bottom_bn" v-if="!bannerLoading">
        <BannerSwiper>
          <template #content>
            <swiper-slide
                v-for="{file,linkUrl, id} in banners"
                :key="id">
              <img
                  @click="openUrl(linkUrl)"
                  :src="`${resourcePath}/banner/display/${file.id}`"
                  alt="배너">
            </swiper-slide>
          </template>
        </BannerSwiper>
      </section>
    </template>
    <template v-else>
      <section class="ms_info" style="text-align: center; margin-top: 200px;">
        <PulseLoader :color="`#6d6d6d`"/>
      </section>
    </template>
    <Transition>
      <div id="myModal2"
           class="modal-custom modal-custom-act"
           v-if="isOpenQR"
           @click="eventCapturing($event.target)">

        <div class="modal-content">
          <div class="modal-header">
          <span class="close-modal">
            <img src="@/assets/images/btn_closeX.png"
                 @click="showQRCode"
                 alt=""/>
          </span>
            <h2 @click="showQRCode">QR 코드</h2>
          </div>
          <div class="modal-body qr_modal">
            <div class="qr_box">
              <QrcodeVue :value="qrCodeValue" :size="250"/>
            </div>
            <p>해당 QR코드를 직원에게 제시해주세요.</p>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import SwiperComponent from "@/components/library/Swiper"
import BannerSwiper from "@/components/library/BannerSwiper"
import QrcodeVue from 'qrcode.vue'
import {SwiperSlide} from 'swiper/vue';
import {mapActions, mapGetters, mapMutations} from "vuex";
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {
  name: "MyMembership",
  components: {
    SwiperComponent,
    SwiperSlide,
    BannerSwiper,
    QrcodeVue,
    PulseLoader
  },
  created() {
    this.setData()
  },
  computed: {
    ...mapGetters({
      membershipList: 'getUserMembershipInfo',
      isMembershipLoading: 'getIsGettingMembershipLoading',
      memberName: 'getMemberName',
      usableList: 'getUsableCouponList',
      usableLast: 'getUsableCouponLast',
      usablePage: 'getUsableCouponPage',
      usableTotalElements: 'getUsableCouponTotalElements',
      allList: 'getAllCouponList',
      allLast: 'getAllCouponLast',
      allPage: 'getAllCouponPage',
      allTotalElements: 'getAllCouponTotalElements',
      banners: 'getMainBanners',
      isCouponLoading: 'getIsCouponLoading'
    }),
    resourcePath() {
      return process.env.VUE_APP_API_URL
    }
  },
  data() {
    return {
      couponTab: null,
      isOpen: false,
      isLoading: false,
      couponName: "",
      couponValue: "",
      swiperComponents: "membership",
      isOpenQR: false,
      qrCodeValue: "",
      bannerLoading: false
    }
  },

  methods: {
    ...mapActions([
      "getCoupons",
      "getBanners"
    ]),
    ...mapMutations([
      'setMembershipInfoInMenu',
      'resetCouponStates',
    ]),
    setClassType(membershipName) {
      if(membershipName.includes('쟈스민')) {
        return 'jas'
      }
      if (membershipName.includes('제네시스')) {
        return 'smart'
      }
      if (membershipName.includes('스마트')) {
        return 'smart'
      }
      if (membershipName.includes('골드')) {
        return 'gold'
      }
      if (membershipName.includes('플래티넘')) {
        return 'platinum'
      }
    },
    async setData() {
      this.isLoading = true
      try {
        this.changeCouponTab()
        this.resetCouponStates()
        this.bannerLoading = true
        await Promise.all([
          this.unUsableCoupons(0),
          this.allCoupons(0),
          this.getBanners({payload: {exposurePosition: "MAIN"}})
        ])
      } catch (e) {
        if (e.response.data.message) alert(e.response.data.message)
      } finally {
        this.isLoading = false
        this.bannerLoading = false
      }
    },
    async unUsableCoupons(page) {
      try {
        await this.fetchCoupons({
          useType: "NONE",
          page: page,
          available: true,
          cancel: false
        })
      } catch (e) {
        console.log(e)
      }
    },
    async allCoupons(page) {
      try {
        await this.fetchCoupons({
          page: page,
          available: true,
          cancel: false,
        })
      } catch (e) {
        console.log(e)
      }
    },
    async fetchCoupons(params) {
      try {
        await this.getCoupons({payload: params})
      } catch (e) {
        console.log(e)
      }
    },
    showBarcode(name, value) {
      this.couponName = name
      this.couponValue = String(value)
      this.isOpen = !this.isOpen
      if (this.isOpen) document.documentElement.classList.add("overflow-y-hidden");
      else document.documentElement.classList.remove("overflow-y-hidden");
    },
    showQRCode(value) {
      this.qrCodeValue = String(value)
      this.isOpenQR = !this.isOpenQR
      if (this.isOpenQR) document.documentElement.classList.add("overflow-y-hidden");
      else document.documentElement.classList.remove("overflow-y-hidden");
    },
    eventCapturing(e) {
      if (e.id === "myModal2") {
        this.isOpen = false
        this.isOpenQR = false
        document.documentElement.classList.remove("overflow-y-hidden");
      }
    },
    changeTab(num, membershipId) {

      this.$emit('changeTab', {
        num: num,
        membershipId: membershipId
      })
    },
    openUrl(linkUrl) {
      window.open(linkUrl)
    },
    beforeInit({activeIndex}) {
      this.setMembershipInfoInMenu(this.membershipList[activeIndex])
    },
    onSlideChange({activeIndex}) {
      this.setMembershipInfoInMenu(this.membershipList[activeIndex])
    },
    changeCouponTab(value) {
      if (value) this.couponTab = value
      else this.couponTab = null
    }
  }
}
</script>

<style scoped>
/*.swiper-slide img{*/
/*  max-height: 150px;*/
/*}*/
.modal-content2 {
  min-height: 220px;
}

.modal-body3 {
  height: 100%;
}

.vc_list li button.used {
  border: 1px solid #bbbbbb;
  color: #bbbbbb;
  background: #fff;
}

.vc_list li span {
  position: absolute;
  right: 0;
  bottom: 10px;
  font-weight: 500;
  font-size: 10px;
  padding: 4px 10px;
  letter-spacing: -1px;
}

.modal_btn_area .close-modal4 {
  padding: 10px 0;
}
</style>
