<template>
  <header v-if="
  ($route.path !== '/edit-password-comp' && $route.path !== '/password-reset-comp') &&
  !$route.path.includes('/sign')">
    <div class="btn_pgback" @click="$router.go(-1)">
      <img src="@/assets/images/btn_back.png" v-if="$route.path !== '/'" alt="뒤로가기버튼">
    </div>
    <h1 @click="$router.push('/')">파르나스</h1>
    <h3 v-if="$route.path !== '/phone-auth'">{{$route.meta.title}}</h3>
    <button class="btn" @click="setIsMenuOpen"></button>
  </header>
  <button id="page_cover" class="page_cover" :class="{'open': open || isOnBlackPageCover}"></button>
</template>

<script>
import {mapGetters, mapMutations} from "vuex"
export default {
  name: "HeaderView",
  computed: {
    ...mapGetters({
      open: "getIsMenuOpen",
      isOnBlackPageCover: 'getIsOnBlackPageCover'
    })
  },
  methods: {
    ...mapMutations(["setIsMenuOpen"])
  }
}
</script>

<style scoped>
</style>
