import axios from "axios";
import store from "@/store";
import router from "@/router";

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "*",
        "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept, Authorization",
    },
});

axiosInstance.interceptors.request.use(
    function (config) {
        const accessToken = store.getters.getAccessToken;
        if (accessToken) config.headers["Authorization"] = `Bearer ${accessToken}`;
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);
let isTokenRefreshing = false;
let refreshSubscribers = [];

const onTokenRefreshed = () => {
    refreshSubscribers.forEach((callback) => callback());
};

const addRefreshSubscriber = (callback) => {
    refreshSubscribers.push(callback);
};
const flushSubscriber = () => {
    refreshSubscribers = [];
};

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        if (window.location.pathname === "/" && !store.getters.getAccessToken) {
            return false
        }
        const {
            config: originalRequest,
            response: {status},
        } = error;
        if (status === 403) {
            await router.push("/login");
        } else if (status === 401) {
            let retryOriginalRequest = new Promise((resolve) => {
                addRefreshSubscriber(() => {
                    resolve(axiosInstance(originalRequest));
                });
            });
            if (!isTokenRefreshing) {
                isTokenRefreshing = true;
                try {
                    const accessToken = await store.getters.getAccessToken;
                    const refreshToken = await store.getters.getRefreshToken;
                    let {
                        data: {accessToken: newAccessToken},
                    } = await axiosInstance.post(
                        `/members/token/refresh`,
                        {
                            refreshToken: refreshToken,
                            accessToken: accessToken,
                        }
                    );
                    await store.commit("setAccessToken", {accessToken: newAccessToken});
                } catch (e) {
                    await store.commit("setAccessToken", {accessToken: null});
                    await store.commit("setRefreshToken", {refreshToken: null});
                    flushSubscriber();
                    await router.push("/login");
                }
                isTokenRefreshing = false;
                onTokenRefreshed();
            }
            return retryOriginalRequest;
        }else if(status === 412) {
            await store.commit("resetMember");
            await store.commit("resetAuth");
            await store.commit("resetCouponStates");
            await store.commit("setMenuClose");
            alert("중복 로그인으로 인해 로그아웃 됩니다.")
            await router.push("/");
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
