<template>
  <body :id="$route.meta.bodyId">
  <div id="wrap" class="login_layout">
    <slot/>
    <footer-vue/>
  </div>
  </body>
</template>

<script>
import FooterVue from "@/components/common/Footer";

/**
 * @로그인페이지
 */

export default {
  name: "LoginLayout",
  components: {FooterVue},
  created() {
    document.documentElement.classList.remove("overflow-y-hidden");
  }
}
</script>

<!--<style scoped lang="scss">-->
<!--  @import url("/src/assets/css/login.css");-->
<!--</style>-->
