import axiosInstance from "@/api/proxyHandler";

export default {
    /**
     * 회원 멤버십 정보
     */
    async getMyMembershipInfo(params) {
        return await axiosInstance.get(`/memberships`, {
            params: {
                ...params
            }
        })
    },

    /**
     * 멤버십 싱크
     */
    async syncMembership() {
        return await axiosInstance.post(`/members/membership-update`)
    },

    /**
     * 푸시 광고성 동의
     */
    async patchPushAgree(model) {
        return await axiosInstance.patch(`/members/push-agree`, {
            ...model
        })
    },

    /**
     * 쿠폰 목록
     * @param params
     */
    async getCoupons(params) {
        return await axiosInstance.get(`/coupons`, {
            params: {
                sort: 'id,asc',
                ...params,
            }
        })
    },

    /**
     * 쿠폰 상세
     * @param id
     */
    async getCoupon(id) {
        return await axiosInstance.get(`/coupons/${id}`)
    },

    /**
     * 회원이 속해 있는 멤버십 쿠폰중 사용할 수 있는 쿠폰
     */
    async getMembershipUsableCoupons(params) {
        return await axiosInstance.get(`coupons`, {
            params: {
                ...params
            }
        })
    },

    /**
     * 메인 -> 레스토랑 이용내역
     */
    async getHistory(page, id) {
        return await axiosInstance.get(`/memberships/restaurant/history/${id}`, {
            params: {
                page: page,
                rowCnt: 10,
            }
        })
    },

    async getNoticePopups() {
        return await axiosInstance.get(`/boards/notice/popups`, {
            params: {
                main: true
            }
        })
    },
};
