// state
const state = {
    isOnBlackPageCover: false
}

// mutations
const mutations = {
    setIsOnBlackPageCover: (state, value) => {
        state.isOnBlackPageCover = value
        if(state.isOnBlackPageCover){
            document.documentElement.classList.add("overflow-y-hidden");
        }else {
            document.documentElement.classList.remove("overflow-y-hidden");
        }
    }
}

// getters
const getters = {
    getIsOnBlackPageCover(state){
        return state.isOnBlackPageCover
    },
}

export default {
    state,
    mutations,
    getters
}
