<template>
  <component :is="layout">
    <slot/>
  </component>
</template>
<script>
/**
 * @description 가장 상위의 Layout
 */
import FullLayout from "@/views/layout/FullLayout";
import MainLayout from "@/views/layout/MainLayout";
import LoginLayout from "@/views/layout/LoginLayout";

export default {
  name: "DefaultLayout",
  components: {
    FullLayout,
    MainLayout,
    LoginLayout
  },
  props: {
    layout: {
      type: String,
      default: "MainLayout",
    },
  },
};
</script>

<style scoped></style>
