const mainRoutes = [
    {
        path: "/coupon-usable",
        name: "CouponUsable",
        meta: {
            bodyId: "mypage_vc",
            layout: "MainLayout",
            title: "사용가능한 바우처"
        },
        component: () => import("@/views/main/CouponUsable"),
    },
    {
        path: "/coupon-promotion",
        name: "CouponPromotion",
        meta: {
            bodyId: "mypage_vc",
            layout: "MainLayout",
            title: "프로모션 바우처"
        },
        component: () => import("@/views/main/CouponUsable"),
    },
    {
        path: "/coupon-register",
        meta: {
            bodyId: "mypage_vc_detail",
            layout: "MainLayout",
            title: "바우처 등록"
        },
        component: () => import("@/views/main/CouponRegister"),
    },
    {
        path: "/coupon-history",
        name: "coupon-history",
        meta: {
            bodyId: "mypage_vc",
            layout: "MainLayout",
            title: "바우처 사용내역"
        },
        component: () => import("@/views/main/CouponHistory"),
    },
    {
        path: "/coupon-history-detail",
        meta: {
            bodyId: "mypage_vc_detail",
            layout: "MainLayout",
            title: "바우처 사용내역"
        },
        component: () => import("@/views/main/CouponHistoryDetail"),
    },
    {
        path: "/gift",
        name: "gift",
        meta: {
            bodyId: "mypage_vc_detail",
            layout: "MainLayout",
            title: "선물"
        },
        component: () => import("@/views/main/CouponGift"),
    },
    {
        path: "/coupon-detail",
        name: "CouponDetail",
        meta: {
            bodyId: "mypage_vc_detail",
            layout: "MainLayout",
            title: "바우처 상세"
        },
        component: () => import("@/views/main/CouponDetail"),
    },
    {
        path: "/faq",
        meta: {
            bodyId: "faq",
            layout: "MainLayout",
            title: "자주하는 질문"
        },
        component: () => import("@/views/board/Faq.vue"),
    },
    {
        path: "/notice",
        meta: {
            bodyId: "notice",
            layout: "MainLayout",
            title: "공지사항"
        },
        component: () => import("@/views/board/Notice"),
    },
    {
        path: "/notice/:id",
        meta: {
            bodyId: "notice",
            layout: "MainLayout",
            title: "공지사항"
        },
        component: () => import("@/views/board/NoticeDetail.vue"),
    },
    {
        path: "/phone-auth-main",
        meta: {
            bodyId: "main_nonCertify",
            layout: "MainLayout",
        },
        component: () => import("@/views/auth/PhoneAuthorize"),
    },
    {
        path: "/membership-terms",
        name: "MembershipTerms",
        meta: {
            bodyId: "join",
            layout: "LoginLayout",
        },
        component: () => import("@/views/main/MembershipTerms"),
    }
]

export default mainRoutes
