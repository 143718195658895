import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate';
import login from './modules/login';
import menu from './modules/menu';
import member from "./modules/member";
import coupon from "./modules/coupon";
import banner from "./modules/banner";
import auth from "@/store/modules/auth";
import modal from "@/store/modules/modal";

export default createStore({
    modules: {
        login,
        menu,
        member,
        coupon,
        banner,
        auth,
        modal,
    },
    plugins: [
        createPersistedState({
            storage: window.localStorage,
            key: 'parnas',
            paths: ["login", "member"]
        })
    ],
})
