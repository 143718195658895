import api from "@/api/modules/main"
import {isKeyExists, makeDateFormatYYYYMMDD} from "@/util";

// state
const state = {
    usableCouponList: [],
    usableCouponPage: 0,
    usableCouponTotalElements: 0,
    usableCouponLast: false,
    allCouponList: [],
    allCouponPage: 0,
    allCouponLast: false,
    allCouponTotalElements: 0,
    couponList: [],
    couponPageNumber: 0,
    isCouponLoading: false,
    membershipUsableCoupons: [],
    membershipUsableCouponsTotalElement: 0
}

// mutations
const mutations = {
    resetCouponStates: (state) => {
        state.usableCouponList =  []
        state.usableCouponPage =  0
        state.usableCouponTotalElements =  0
        state.usableCouponLast =  false
        state.allCouponList =  []
        state.allCouponPage =  0
        state.allCouponLast =  false
        state.allCouponTotalElements =  0
        state.couponList =  []
        state.couponPageNumber = 0
        state.isCouponLoading = false
        state.membershipUsableCoupons = []
        state.membershipUsableCouponsTotalElement = 0
    },
    setIsCouponLoading: (state, value) => {
        state.isCouponLoading = value
    },
    setUsableCouponList: (state, {content}) => {
        state.usableCouponList = [...state.usableCouponList, ...content]
    },
    setUsableCouponPage: (state, {number}) => {
        state.usableCouponPage = number;
    },
    setUsableCouponLast: (state, {last}) => {
        state.usableCouponLast = last;
    },
    setUsableCouponTotalElements: (state, {totalElements}) => {
        state.usableCouponTotalElements = totalElements
    },
    //-------------------------------
    setAllCouponList: (state, {content}) => {
        state.allCouponList = [...state.allCouponList, ...content]
    },
    setAllCouponPage: (state, {number}) => {
        state.allCouponPage = number;
    },
    setAllCouponLast: (state, {last}) => {
        state.allCouponLast = last;
    },
    setAllCouponTotalElements: (state, {totalElements}) => {
        state.allCouponTotalElements = totalElements
    },

    setCouponDetailInfo: (state, {coupon}) => {
        state.couponDetailInfo = coupon
    },
    setCouponPageNumber: (state, {number}) => {
        state.couponPageNumber = number
    },
    setMembershipUsableCoupons: (state, {content}) => {
        state.membershipUsableCoupons = [...state.membershipUsableCoupons, ...content]
    },
    setMembershipUsableCouponsTotalElement: (state, {totalElements}) => {
        state.membershipUsableCouponsTotalElement = totalElements
    }
}

// actions
const actions = {
    getCoupons: async ({commit}, {payload}) => {
        try {
            commit("setIsCouponLoading", true);
            let {
                data: {
                    content, totalElements, number, last
                }, status,
            } = await api.getCoupons(payload)
            content = content.map(item => {
                return {
                    ...item,
                    usedAt: makeDateFormatYYYYMMDD(item.usedAt)
                }
            })
            if (isKeyExists(payload, "useType") && payload.useType === "NONE") {
                commit("setUsableCouponList", {content: content});
                commit("setUsableCouponPage", {number: number});
                commit("setUsableCouponLast", {last: last});
                commit("setUsableCouponTotalElements", {totalElements: totalElements});
            } else {
                commit("setAllCouponList", {content: content});
                commit("setAllCouponPage", {number: number});
                commit("setAllCouponLast", {last: last});
                commit("setAllCouponTotalElements", {totalElements: totalElements});
            }
            commit("setIsCouponLoading", false);
            return {
                status: status
            }
        } catch (e) {
            commit("setIsCouponLoading", false);
            if(e.response.data.message) alert(e.response.data.message)
            return false
        }
    },
    getCoupon: async ({commit}, {id}) => {
        const {
            data, status
        } = await api.getCoupon(id);
        commit("setCouponDetailInfo", {coupon: data})
        return {
            status: status
        }
    },
    getMembershipUsableCoupons: async ({commit}, payload) => {
        const {
            data: {
                content, totalElements
            }
        } = await api.getMembershipUsableCoupons(payload);
        commit("setMembershipUsableCoupons", {content: content})
        commit("setMembershipUsableCouponsTotalElement", {totalElements: totalElements})
    },
}

// getters
const getters = {
    getIsCouponLoading(state) {
        return state.isCouponLoading
    },
    getUsableCouponList(state) {
        return state.usableCouponList
    },
    getUsableCouponPage(state) {
        return state.usableCouponPage
    },
    getUsableCouponLast(state) {
      return state.usableCouponLast
    },
    getUsableCouponTotalElements(state) {
        return state.usableCouponTotalElements
    },
    getAllCouponList(state) {
        return state.allCouponList
    },
    getAllCouponPage(state) {
        return state.allCouponPage
    },
    getAllCouponTotalElements(state) {
        return state.allCouponTotalElements
    },
    getAllCouponLast(state) {
        return state.allCouponLast
    },
    getCouponDetailInfo(state) {
        return state.couponDetailInfo
    },
    getCouponPageNumber(state) {
        return state.couponPageNumber
    },
    getMembershipUsableCoupons: (state) => {
        return state.membershipUsableCoupons
    },
    getMembershipUsableCouponsTotalElement: (state) => {
        return state.membershipUsableCouponsTotalElement
    }
}

export default {
    state, mutations, actions, getters
}
