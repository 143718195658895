<template>
  <swiper
      :pagination="true"
      :autoplay="{delay: 3000,disableOnInteraction: false}"
      :modules="modules"
      class="mySwiper mySwiper1 mySwiper2"
  >
    <slot name="content"></slot>
  </swiper>
</template>
<script>
// https://swiperjs.com/
// import Swiper core and required modules
import {Pagination, Autoplay} from 'swiper';

// Import Swiper Vue.js components
import {Swiper} from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/bundle';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

// Import Swiper styles
export default {
  name: "BannerSwiperComponent",
  components: {
    Swiper,
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log('slide change');
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Pagination, Autoplay],
    };
  },
};
</script>

<style scoped>
/*.swiper_wrapper {*/
/*  transform: translate3d(0px, 0px, 0px) !important;*/
/*}*/
</style>
