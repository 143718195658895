<template>
  <swiper
      :pagination="{
      type: 'fraction',
    }"
      :navigation="true"
      @beforeInit="beforeInit"
      @slideChange="onSlideChange"
      :modules="modules"
      class="mySwiper mySwiper3"
  >
    <slot name="content">
    </slot>
  </swiper>
</template>
<script>
// https://swiperjs.com/
// import Swiper core and required modules
import { Navigation, Pagination } from 'swiper';

// Import Swiper Vue.js components
import { Swiper } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/bundle';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import {useRoute} from "vue-router"

// Import Swiper styles
export default {
  name: "SwiperComponent",
  components: {
    Swiper,
  },
  setup(_, {emit}) {
    const route = useRoute()
    const beforeInit = ({activeIndex}) => {
      emit("beforeInit", activeIndex)
    };
    const onSlideChange = ({activeIndex}) => {
      emit("onSlideChange", activeIndex)
    };
    return {
      beforeInit,
      onSlideChange,
      modules: route.path === "/my-page" ? [Navigation] : [Navigation, Pagination],
    };
  },
};
</script>

<style>
</style>
