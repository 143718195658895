<template>
    <div id="menu" :class="{'open': open}">
        <div class="close" @click="setIsMenuOpen"></div>
        <div class="menu_top login" v-if="token">
            <template v-if="membershipList.length > 0 && !membershipLoading">
                <div class="menu_top_01">
                    <p>{{ memberName }} 님<span>{{ info.membership["name"] }}</span></p>
                    <button @click="movePage({path: '/my-page'})"><span class="arrow-next"></span></button>
                </div>
                <div class="menu_top_02">
                    <p>멤버십 번호<span>{{ info.membershipIssuedSeq }}</span></p>
                    <p>만료일<span>{{ info.membershipIssuedEndAt }}</span></p>
                </div>
            </template>
            <template v-if="membershipList.length <= 0 && !membershipLoading">
                <div class="menu_top_01">
                    <p>{{ memberName }} 님 가입된 멤버십이 없습니다.</p>
                    <button @click="movePage({path: '/my-page'})"><span class="arrow-next"></span></button>
                </div>
                <div class="menu_top_02">
                    <p>멤버십을 가입하여 더 많은 혜택을 누려보세요!</p>
                </div>
            </template>
        </div>
        <div class="menu_top" v-if="!token">
            <div class="menu_top_01">
                <p>로그인을 해주세요.</p>
                <button @click="$router.push('/login')"><span class="arrow-next"></span></button>
            </div>
        </div>
        <SwiperComponent>
            <template #content>
                <swiper-slide
                        v-for="{file,linkUrl, id} in banners"
                        :key="id">
                    <img
                            @click="openUrl(linkUrl)"
                            :src="`${resourcePath}/banner/display/${file.id}`"
                            alt="배너">
                    <!--              <img :src/="file.fullPath"-->
                </swiper-slide>
            </template>
        </SwiperComponent>
        <ul class="AccordionMenu type01 SlideMenu">
            <li class="">
                <div class="Question" @click="movePage({path: '/'})">HOME</div>
            </li>
            <li :class="{'show': menusOpenFlags[0]}" @click="menuOpen(0)">
                <div class="Question">HOTEL</div>
                <div class="Answer">
                    <ul>
                        <li onclick="window.open('https://seoul.intercontinental.com/grandicparnas')">그랜드 인터컨티넨탈 서울
                            파르나스
                        </li>
<!--                        <li onclick="window.open('https://seoul.intercontinental.com/iccoex/')">인터컨티넨탈 서울 코엑스</li>-->
                        <li onclick="window.open('https://www.parnashoteljeju.com/ko/main.do')">파르나스 호텔 제주</li>
                    </ul>
                </div>
            </li>
            <li :class="{'show': menusOpenFlags[1]}" @click="menuOpen(1)">
                <div class="Question">PROMOTION</div>
                <div class="Answer">
                    <ul>
                        <li onclick="window.open('https://seoul.intercontinental.com/grandicparnas/m/Promotion/FMembership')">
                            그랜드 인터컨티넨탈 서울 파르나스
                        </li>
<!--                        <li onclick="window.open('https://seoul.intercontinental.com/iccoex/m/Promotion/FMembership')">-->
<!--                            인터컨티넨탈 서울 코엑스-->
<!--                        </li>-->
                    </ul>
                </div>
            </li>
            <li class="" :class="{'show': menusOpenFlags[2]}" @click="menuOpen(2)">
                <div class="Question">오시는길</div>
                <div class="Answer">
                    <ul>
                        <li onclick="window.open('https://seoul.intercontinental.com/welcome/Map')">
                            그랜드 인터컨티넨탈 서울 파르나스
                        </li>
<!--                        <li onclick="window.open('https://seoul.intercontinental.com/welcome/Map')">-->
<!--                            인터컨티넨탈 서울 코엑스-->
<!--                        </li>-->
                        <li onclick="window.open('https://www.parnashoteljeju.com/ko/hotelintro/location.do')">
                            파르나스 호텔 제주
                        </li>
                    </ul>
                </div>
            </li>
            <li :class="{'show': menusOpenFlags[3]}" @click="menuOpen(3)">
                <div class="Question">NOTICE</div>
                <div class="Answer">
                    <ul>
                        <li @click="movePage({path: 'notice'})">공지사항</li>
                        <li @click="movePage({path: 'faq'})">자주하는 질문</li>
                    </ul>
                </div>
            </li>

        </ul>
        <ul class="menu_terms">
            <li onclick="window.open('https://seoul.intercontinental.com/hub/m/customer/Privacy.ic')">개인정보처리방침</li>
            <li onclick="window.open('https://seoul.intercontinental.com/hub/m/customer/TheParnasAgreement.ic')">더파르나스 멤버십 약관
            </li>
            <li onclick="window.open('https://seoul.intercontinental.com/hub/m/customer/WebMemberPolicy.ic')">웹 회원 정책</li>
            <li v-if="token" @click="doLogout">로그아웃</li>
            <li></li>
            <li></li>
            <li></li>
        </ul>
        <br><br>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex"
import SwiperComponent from "@/components/library/BannerSwiper"
import {SwiperSlide} from 'swiper/vue';
import api from "@/api/modules/auth"
import {initUserData} from "@/util";

export default {
    name: "MenuComponent",
    created() {
        this.getBannerList()
    },
    components: {SwiperComponent, SwiperSlide},
    data() {
        return {
            memberInfo: {},
            menusOpenFlags: [false, false, false, false],
        }
    },
    computed: {
        ...mapGetters({
            open: "getIsMenuOpen",
            token: "getAccessToken",
            membershipLoading: "getIsGettingMembershipLoading",
            membershipList: "getUserMembershipInfo",
            info: "getMembershipInfoInMenu",
            memberName: "getMemberName",
            banners: "getMenuBanners"
        }),
        resourcePath() {
            return process.env.VUE_APP_API_URL
        }
    },
    methods: {
        ...mapActions({getBanners: "getBanners", logout: "doLogout"}),
        ...mapMutations([
            "setIsMenuOpen",
            "setMenuClose",
            "resetAuth",
            "resetMember",
            "resetCouponStates",
        ]),
        movePage({path}) {
            this.setIsMenuOpen()
            this.$router.push(path)
        },
        async getBannerList() {
            await this.getBanners({payload: {exposurePosition: "MENU"}})
        },
        menuOpen(idx) {
            //이미 열려있으면 닫고 메소드 끝
            if (this.menusOpenFlags[idx]) {
                this.menusOpenFlags[idx] = false
                return false
            }
            //메뉴를 다 닫음
            this.menusOpenFlags = [false, false, false, false]
            //해당 인덱스만 open
            this.menusOpenFlags[idx] = true
        },
        openUrl(linkUrl) {
            window.open(linkUrl)
        },
        async doLogout() {
            if (!confirm("로그아웃 하시겠습니까?")) return false
            try {
                //fcmToken 삭제
                await api.removeFcmToken()

                //로그아웃
                await initUserData()
                if (this.$route.path !== '/') this.$router.replace('/')

            } catch (e) {
                if (e.response.data.message === "No value present") {
                    await initUserData()
                    if (this.$route.path !== '/') this.$router.push('/')
                }
                if (e.response.data.message) alert(e.response.data.message)
            }

        },
    },
}
</script>

<style scoped>
.swiper-slide img {
    max-height: 150px;
}

.AccordionMenu li .Answer {
    display: none;
}

#menu {
    overflow: auto;
}
</style>
