const authRoutes = [
    {
        path: "/login",
        meta: {
            bodyId: "login",
            layout: "LoginLayout"
        },
        component: () => import("@/views/auth/SignIn"),
    },
    {
        path: "/sign-up",
        meta: {
            bodyId: "join",
            layout: "LoginLayout"
        },
        component: () => import("@/views/auth/SignUpJoin"),
    },

    // {
    //     path: "/sign-up",
    //     meta: {
    //         bodyId: "join",
    //         layout: "LoginLayout"
    //     },
    //     component: () => import("@/views/auth/SignUp"),
    // },
    // {
    //     path: "/sign-up-form",
    //     meta: {
    //         bodyId: "join02",
    //         layout: "LoginLayout"
    //     },
    //     component: () => import("@/views/auth/SignUpForm"),
    // },
    // {
    //     path: "/sign-up-finish",
    //     meta: {
    //         bodyId: "join03",
    //         layout: "LoginLayout"
    //     },
    //     component: () => import("@/views/auth/SignUpFinish"),
    // },
    {
        path: "/find-password",
        meta: {
            bodyId: "find",
            layout: "LoginLayout"
        },
        component: () => import("@/views/auth/FindPassword"),
    },
    {
        path: "/password-reset-comp",
        meta: {
            bodyId: "edit_password",
            layout: "MainLayout",
        },
        component: () => import("@/views/myPage/EditPasswordComplete"),
    },
    {
        path: "/find-id",
        meta: {
            bodyId: "find",
            layout: "LoginLayout"
        },
        component: () => import("@/views/auth/FindId"),
    },
    {
        path: "/phone-auth",
        meta: {
            bodyId: "main_nonCertify",
            layout: "MainLayout"
        },
        component: () => import("@/views/auth/PhoneAuthorize"),
    },

]
export default authRoutes
