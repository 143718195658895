import axiosInstance from "@/api/proxyHandler";

export default {
    async getBoards(params) {
        return await axiosInstance.get(`/boards/notice`, {
            params: {
                ...params
            }
        })

    },

    async getBanners(params) {
        return await axiosInstance.get(`/banner`, {
            params: {
                ...params,
                sort: 'orderNum,asc',
            }
        })
    },
}
