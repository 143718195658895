<template>
  <body :id="$route.meta.id">
    <slot/>
  </body>
  <FooterVue/>
</template>

<script>
/**
 * @description 헤더가 없는 full layout
 */
import FooterVue from "@/components/common/Footer";

export default {
  name: "FullScreenLayout",
  components: {
    FooterVue
  }

};
</script>

<style>
/* we will explain what these classes do next! */
.v-enter-active {
  transition: opacity 0.3s ease;
}

.v-enter-from {
  opacity: 0.1;
}
</style>
