<template>
  <div class="error-wrapper" style="text-align: center">
    <p class="error-title">PARNAS HOTEL</p>
    <p class="error-statement">잘못된 접근 입니다.</p>
    <button class="error-page-button" @click="$router.go(-1)">이전 페이지로 이동</button>
  </div>
</template>

<script>
/**
 * @description 404페이지
 */
export default {
  name: "NotFound",
};
</script>

<style scoped>
.error-wrapper{
  height: 100vh;
  padding-top: 250px;
}
p {
  margin: 10px;
}

.error-title {
  font-weight: bold;
}

.error-statement {
  font-size: x-large;
  margin-bottom: 30px;
}

.error-page-button {
  padding: 10px 30px 10px 30px;
  color: #fff;
  background: black;
  border: 1px solid black;
}
</style>
