// state
const state = {
    isMenuOpen: false,
    menu: false,
}

// mutations
const mutations = {
    setIsMenuOpen: (state) => {
        state.isMenuOpen = !state.isMenuOpen
        if(state.isMenuOpen){
            document.documentElement.classList.add("overflow-y-hidden");
        }else {
            document.documentElement.classList.remove("overflow-y-hidden");
        }
    },
    setMenuClose: (state) => {
        state.isMenuOpen = false
        document.documentElement.classList.remove("overflow-y-hidden");
    },
    setMenu: (state) => {
        state.menu = true
    },
}

// actions
const actions = {
}

// getters
const getters = {
    getIsMenuOpen(state){
        return state.isMenuOpen
    },
    getMenu(state){
        return state.menu
    },
}

export default {
    state,
    mutations,
    actions,
    getters
}
