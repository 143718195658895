<template>
  <footer>
    <p>파르나스호텔㈜</p>
    <ul>
      <li>(06164) 서울특별시 강남구 테헤란로 521</li>
      <li>TEL : 02-555-5656</li>
      <li>E-MAIL : grandic@parnas.co.kr</li>
      <li>사업자등록번호 : 120-81-01336</li>
    </ul>
  </footer>
</template>

<script>
export default {
  name: "FooterVue"
}
</script>
