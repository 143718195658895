<template>
  <template v-if="!isLoading">
    <template v-if="membershipList.length > 0">
      <section class="ms_rt_info">
        <div class="top">
          <div class="left">
            <p>더파르나스 <span>{{ membershipInfo.membership["name"] }}</span></p>
            <p>{{ membershipInfo.membershipIssuedSeq }}</p>
          </div>
          <div class="right">
            <dl></dl>
            <dl>
              <dt>만료일</dt>
              <dd>{{ membershipInfo.membershipIssuedEndAt }}</dd>
            </dl>
          </div>
        </div>
        <div class="bottom">
          <div class="left">
            레스토랑 할인 가능 횟수 : {{ totalCnt }}
          </div>
          <div class="right"> 사용 <span>{{ usedCnt }}</span> / 잔여 <span>{{ totalCnt !== '무제한' ? totalCnt - usedCnt : '무제한'}}</span></div>
        </div>
      </section>
    </template>
    <template v-if="membershipList.length <= 0">
      <section class="ms_info" v-if="membershipList.length <= 0">
        <h3><span>THE PARNAS MEMBERSHIP</span></h3>
        <div class="no_mem">
          <p><span>{{ memberName }}</span>님의 더파르나스 멤버십이 미등록 상태입니다.</p>
          <p>영업일 기준 1~2일 이내 사용 등록 후 문자로 안내드립니다.<br>
            <br>
            <span style="font-size: 13px; color: darkgray;">
          문의 사항 <br/>
          더파르나스팀 02-559-7645
          <br>
          theparnas@parnas.co.kr
        </span>
          </p>
        </div>
      </section>
    </template>
    <section class="ms_rs_history">
      <div style="font-size: 12px;margin-bottom: 23px;">
        ※ 로비라운지, 로비 바, 그랜드 델리: 횟수 제한 없이 이용 가능</div>
      <p>이용내역</p>
      <table class="ms_rs_history_table">
        <tr>
          <th>no.</th>
          <th>사용일자</th>
          <th>레스토랑</th>
          <th>할인금액</th>
        </tr>
        <template v-if="list.length > 0">
          <tr v-for="({num, usedAt, businessName, discountAmount}) in list" :key="num">
            <td v-text="num"></td>
            <td v-text="usedAt"></td>
            <td v-text="businessName" class="name"></td>
            <td v-text="`${Number(discountAmount).toLocaleString('ko-KR')}원`"></td>
          </tr>
<!--          <tr>-->
<!--            <td colspan="4" style="text-align: end;">합계: {{totalDiscountAmount}}</td>-->
<!--          </tr>-->
        </template>
        <template v-else>
          <tr>
            <td colspan="4">이용내역이 없습니다</td>
          </tr>
        </template>
        <tr style="border-bottom: none;" @click="loadMore" v-if="!last">
          <td colspan="4" style="text-align: center;">
            <button
                style="
                color: #555555;
                font-size: 12px;
                letter-spacing: -0.04em;
                padding: 4px 10px;">
              {{ isMoreLoading ? "불러오는중.." : "+ 더보기" }}
            </button>
          </td>
        </tr>
      </table>
      <p>
        ※ 상기 금액은 세금, 봉사료 포함입니다.<br/>
        ※ 레스토랑에서 가입과 동시에 할인 적용된 경우, 상단의 사용 횟수에는 포함되지만, 본문 이용내역에는 표시되지 않습니다. 자세한 사항은 더파르나스팀(02-559-7645)으로 문의해 주시기 바랍니다.
      </p>
    </section>
  </template>
  <template v-else>
    <section class="ms_rt_info" style="text-align: center; margin-top: 200px;">
      <PulseLoader :color="`#6d6d6d`"/>
    </section>
  </template>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import api from "@/api/modules/main"
import {mapGetters} from "vuex";
import {makeDateFormatYYYYMMDD} from "@/util";

export default {
  name: "RestaurantHistory",
  created() {
    this.getHistory()
  },
  components: {PulseLoader},
  computed: {
    ...mapGetters({
      membershipInfo: "getMembershipInfoInMenu",
      membershipList: "getUserMembershipInfo",
      memberName: "getMemberName",
    })
  },
  data() {
    return {
      list: [],
      resultCnt: null,
      usedCnt: null,
      remainingCnt: null,
      validCnt: null,
      totalCnt: null,
      totalDiscountAmount: 0,
      page: 1,
      isLoading: false,
      last: true,
      isMoreLoading: false,
    }
  },
  methods: {
    loadMore() {
      this.page = this.page + 1
      this.isMoreLoading = true
      this.getHistory()
    },
    getHistory() {
      this.last = false
      //멤버십이 없을땐 보내지 않도록 설정
      if (!this.membershipInfo.id || this.membershipList.length <= 0) {
        this.last = true
        return false
      }

      //처음 들어올때 로딩바
      if (this.page === 1) this.isLoading = true

      api.getHistory(this.page, this.membershipInfo.id)
          .then(({data: {restaurantHistoryItemList, usedCnt, resultCnt, remainingCnt, totalDiscountAmount}}) => {

            //멤버십 쿠폰 총 사용할 수 있는 개수
            if(this.membershipInfo.membership["name"].includes("스마트")) {
              this.totalCnt = 24
            }else if(this.membershipInfo.membership["name"].includes("골드")) {
              this.totalCnt = 48
            }else if(this.membershipInfo.membership["name"].includes("플래티넘")) {
              this.totalCnt = "무제한"
            }

            //아예 빈값일 경우
            if (this.list.length === 0 && restaurantHistoryItemList === null) {
              this.list = []
              this.last = true
              this.usedCnt = 0
              return false
            }

            //usedCnt: 사용, remainingCnt: 잔여, totalDiscountAmount: 할인 합계
            this.usedCnt = usedCnt ?? 0
            this.remainingCnt = remainingCnt ?? 0
            this.totalDiscountAmount = totalDiscountAmount ?? 0


            //페이징에 따른 리스트 처리
            if (this.page <= 1) {
              this.list = restaurantHistoryItemList.map(item => {
                return {
                  ...item,
                  usedAt: makeDateFormatYYYYMMDD(item.usedAt)
                }
              }) ?? []
            } else if (this.page > 1) {
              this.list = [
                ...this.list,
                ...restaurantHistoryItemList.map(item => {
                  return {
                    ...item,
                    usedAt: makeDateFormatYYYYMMDD(item.usedAt)
                  }
                })
              ]
            }

            //마지막 페이지인지 체크
            if (this.list.length >= resultCnt ) {
              this.last = true
            }

          })
          .catch(e => {
            if (e.response.data.message) alert(e.response.data.message)
          })
          .finally(() => {
            this.isLoading = false
            this.isMoreLoading = false
          })
    },
  }
}
</script>

<style scoped>
.ms_rs_history{
  padding: 6px 20px 20px 20px;
}
table.ms_rs_history_table{
  margin: 6px 0 20px 0;
}
.ms_rt_info .bottom{
  padding: 28px;
}
</style>
