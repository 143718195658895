<script setup>

</script>

<template>
  <section class="ms_card_tit">
    <p></p>
    <p>더파르나스 신한 법인 골드 멤버십</p>
  </section>
  <section class="mem_benefit">
    <h4>혜택 상세</h4>
    <h5>객실 혜택<span>상시 객실 할인</span></h5>
    <ul>
      <li>우대 요금(BFR) 기준 그랜드 인터컨티넨탈 서울 파르나스 & 파르나스 호텔 제주 15% 할인, 인터컨티넨탈 서울 코엑스 20% 할인</li>
      <li>모든 객실 타입 이용 가능</li>
      <!--                    <li class="lsty_none">※ 우대요금(BFR)이란? <br/>객실 점유율에 따른 일별 할인 객실 요금입니다.</li>-->
      <!--                    <li class="lsty_none">-->
      <!--                        ※ 파르나스 호텔 제주 할인 제외 :<br/>-->
      <!--                        2023.1.21~23, 7.20~8.20, 9.28~10.2, 12.23~31,<br/>-->
      <!--                        2024.2.9~12, 7.20~8.20, 9.13~18, 12.23~31<br/>-->
      <!--                        문의 : 064-801-5100 www.parnashoteljeju.com-->
      <!--                    </li>-->
    </ul>
    <ul class="ul_sm">
      <li class="" style="line-height: 20px; padding-left: 15px;">우대요금(BFR)이란? <br/>객실 점유율에 따른 일별 할인 객실
        요금입니다.
      </li>
    </ul>
    <ul class="ul_sm">
      <li class="" style="line-height: 20px; padding-left: 15px;">
        파르나스 호텔 제주 할인 제외 :<br/>
        2023.1.21~23, 7.20~8.20, 9.28~10.2, 12.23~31,<br/>
        2024.2.9~12, 7.20~8.20, 9.13~18, 12.23~31<br/>
        문의 : 064-801-5100 www.parnashoteljeju.com
      </li>
    </ul>
    <h5>식음료 혜택<span>레스토랑 할인</span></h5>
    <ul>
      <li>레스토랑 할인 횟수 : 48회</li>
      <li>
        <dl>
          <dt>인원에 따른 식사 할인</dt>
          <dd>
            <table>
              <tr>
                <th>인원수</th>
                <th>할인 혜택</th>
              </tr>
              <tr>
                <th>1~2인 식사 <br/>주문시</th>
                <td>
                  식사 금액의 20% 할인
                </td>
              </tr>
              <tr>
                <th>3~7인 식사 <br/>주문시</th>
                <td>1인분 식사 무료 제공<br/>
                  (1인 평균 식사 요금 할인)
                </td>
              </tr>
              <tr>
                <th>8~19인 식사 <br/>주문시</th>
                <td>2인분 식사 무료 제공<br/>
                  (2인 평균 식사 요금 할인)
                </td>
              </tr>
              <tr>
                <th>20~30인 식사 <br/>주문시</th>
                <td>식사 금액의 10% 할인</td>
              </tr>
            </table>
            <dl class="mem_benefit_ps">
              <dt>대상 레스토랑</dt>
              <dd>웨이루, 하코네, 그랜드 키친, 스카이 라운지, 아시안 라이브, 브래서리, 콘페티</dd>
            </dl>
          </dd>
        </dl>
      </li>
      <li>
        <dl>
          <dt>음료 할인</dt>
          <dd>위스키, 꼬냑, 와인을 병으로 주문하실 경우 10% 할인(다른 음료 및 주류 제외)</dd>
        </dl>
      </li>
      <li>
        <dl>
          <dt>로비 라운지, 로비 바, 그랜드 델리, 폰드메르 라운지 & 바 식음료 할인</dt>
          <dd>식음료 이용 금액의 10% 할인(횟수 제한 없이 이용 가능)</dd>
        </dl>
      </li>
    </ul>
    <h4>바우처 혜택</h4>
    <ul>
      <li>
        <dl>
          <dt>‘골드’ 무료 숙박권 1매(아래 호텔 중 택 1)</dt>
          <dd>
            <table>
              <tr>
                <th>호텔</th>
                <th>혜택 및 사용 제외일</th>
              </tr>
              <tr>
                <td class="table_title">
                  그랜드 인터컨티넨탈 서울 파르나스
                </td>
                <td class="table_exp">
                  주니어 스위트 <br>
                  (2인 조식 포함) <br/><br>
                  ※사용 제외: <br/>
                  12월 23~25,30,31일
                </td>
              </tr>
              <tr>
                <td class="table_title">
                  인터컨티넨탈 서울 코엑스
                </td>
                <td class="table_exp">
                  클래식 룸 <br>
                  (2인 조식 포함)<br/><br>
                  ※사용 제외: <br/>
                  12월 23~25,30,31일
                </td>
              </tr>
              <tr>
                <td class="table_title">
                  파르나스 호텔 제주
                </td>
                <td class="table_exp">
                  주중(일~금) 프리미어 패밀리 트윈, <br>
                  일반전망<br/>
                  (조식 불포함, 3인 기준)<br/><br>
                  ※사용 제외: <br>
                  매주 토요일, 공휴일(대체공휴일) <br>
                  및 성수기<br/>
                  (2023년: 5.26~28, 6.3~5, 7.20~8.20, 9.28~10.2, 12.23~31<br/>
                  2024년: 2.9~12, 7.20~8.20, 9.13~18, 12.23~31)
                </td>
              </tr>
            </table>
          </dd>
        </dl>
      </li>
    </ul>
    <ul class="ul_sm">
      <li>객실 무료 숙박권을 통한 객실 예약은 일반 객실 예약과 구분되어 운영되며, 호텔 별로 예약 가능한 객실 수가 제한되어 있습니다.</li>
    </ul>
    <ul>
      <li>
        <dl>
          <dt>레스토랑 5만원 이용권 6매</dt>
          <dd>
            <table>
              <tr>
                <th style="width: 25%;">바우처</th>
                <th>호텔</th>
                <th>영업장</th>
              </tr>
              <tr>
                <td rowspan="3">
                  레스토랑 이용권
                </td>
                <td>
                  그랜드 인터컨티넨탈 서울 파르나스
                </td>
                <td>
                  웨이루, 하코네, 그랜드 키친
                </td>
              </tr>
              <tr>
                <td>
                  인터컨티넨탈 서울 코엑스
                </td>
                <td>
                  브래서리, <br/>스카이 라운지,<br/> 아시안 라이브
                </td>
              </tr>
              <tr>
                <td>
                  파르나스 호텔 제주
                </td>
                <td>
                  콘페티, 폰드메르 라운지 & 바
                </td>
              </tr>
            </table>
          </dd>
        </dl>
      </li>
    </ul>
    <ul class="ul_sm">
      <li>모바일 바우처 이용 시, 더파르나스 모바일 앱 > 나의 멤버십 화면 > 바우처 [사용하기] 버튼을 통해 이용 조건들을 반드시 참고해 주시기 바 랍니다.</li>
    </ul>
    <ul class="ul_sm" style="margin-bottom: 0">
      <li>파르나스 호텔 제주의 경우 ‘델리’ 영업장 미운영으로 일부 상품 적용이 제한됩니다.</li>
    </ul>
    <ul class="hotel-jeju-except">
      <li>하우스 케이크 교환권 : 폰드메르 라운지 5만원 이용권으로 대체 (Food 및 음료 적용 가능, 케이크·베이커리·주류 적용 불가)</li>
      <li>그랜드 델리 부티크 와인샵 5만원 이용권: 폰드메르 바 5만원 이용권으로 대체 (Food 및 음료 적용 가능, 주류 적용 불가)</li>
    </ul>
    <h4>이용 안내</h4>
    <h5>공통</h5>
    <ul>
      <li>회원 혜택 적용 시 반드시 더파르나스 모바일 멤버십 카드 또는 모바일 바우처를 제시하여 주시기 바랍니다.</li>
      <li>모바일 바우처 이용 시, 더파르나스 모바일 앱 -> 나의 멤버십 화면 -> 바우처 [사용하기] 버튼을 통해 이용 조건들을 반드시 참고해 주시기 바랍니다.</li>
      <li>더파르나스 멤버십은 개인 명의의 회원으로 본인만 사용이 가능하며, 할인 적용 시 본인 확인을 요청드릴 수 있습니다.</li>
      <li>더파르나스 이 외의 상품권, 멤버십, 제휴 및 신용 카드 등 할인 및 혜택은 중복 적용되지 않습니다.</li>
      <li>스페셜 프로모션의 경우, 할인 혜택이 제한될 수 있습니다.</li>
      <li>이용 대상 영업장 및 혜택, 제휴 특전 등 호텔의 사정에 따라 조정될 수 있습니다.</li>
    </ul>
    <h5>식음</h5>
    <ul>
      <li>식사 할인의 경우 소수점 이하의 할인율은 적용되지 않습니다. 또한, 동시에 여러 테이블에서 이용하거나, 인원을 나누어서 할인이 적용되지 않습니다.</li>
      <li>연회 행사 및 룸 서비스 이용 시 레스토랑 할인 혜택이 제공되지 않습니다.</li>
    </ul>
    <h5>객실</h5>
    <ul>
      <li style="word-break: break-all">더파르나스 할인 요금 적용 및 객실 숙박권 이용은 객실예약실을 통하여 가능합니다.<br/>
        (온라인 예약 불가능, 객실 예약실: 그랜드 인터컨티넨탈 서울 파르나스, 인터컨티넨탈 서울 코엑스 02-559-7777, reservation@parnas.co.kr /
        파르나스 호텔 제주 064-801-5555, parnashoteljeju@parnas.co.kr)
      </li>
      <li>반드시 숙박권 번호로 사전 예약해주시기 바라며, 체크인 시 숙박권을 제시하셔야 이용이 가능합니다.</li>
      <li>1박에 2개의 객실까지 본인에 한 하여 이용 가능하며, 반드시 사전 예약해주시기 바랍니다.</li>
      <li>객실 할인 및 무료 숙박권은 2인 기준이며 3인 이상 이용 시, 요금이 별도로 추가 부과됩니다.</li>
      <li>객실 무료 숙박권을 통한 객실 예약은 일반 객실 예약과 구분되어 운영되며, 호텔 별로 예약 가능한 객실 수가 제한되어 있습니다.</li>
      <li>객실 할인 및 무료 숙박권은 IHG One Rewards 포인트 적립 및 앰버서더, 로얄 앰버서더 혜택을 포함한 다른 혜택과 중복 적용이 불가합니다.</li>
    </ul>
    <h4>더파르나스 멤버십 약관</h4>
    <ul>
      <li>제1조 회원 가입 자격은 개인에 한하며, 법인은 가입할 수 없습니다.</li>
      <li>제2조 회원 카드 및 바우처의 유효기간은 발급일로부터 1년입니다. (유효기간 연장 불가)</li>
      <li>제3조 회원 카드 및 바우처는 본인 이외에는 사용할 수 없으며 타인에게 대여, 양도 및 담보 제공 등의 목적으로 사용할 수 없습니다.</li>
      <li>제4조 회원카드는 신용카드 용도로 사용할 수 없습니다.</li>
      <li>제5조 회원은 과실로 인한 카드의 분실, 도난, 파손시 발급받은 호텔 더파르나스팀에 즉시 통지하여야 하며, 호텔의 판단에 의하여 재발급합니다. (단, 재발급시 별도의 수수료
        비용 1만원
        및 회원 요청에 의한 특별한 배송은 수취인이 부담하셔야 합니다.)
      </li>
      <li>제6조 호텔이 회원에게 제공한 바우처는 재발급하지 않으며, 현금 또는 다른 상품으로 교환할 수 없습니다. 또한 발급된 바우처는 어떠한 경우에도 기간연장이 적용되지
        않습니다.
      </li>
      <li>제7조 회원 특전은 호텔이 제공하는 기타 다른 할인 혜택 및 바우처 등과 중복 적용되지 않습니다.</li>
      <li>제8조 회원은 호텔 이용 시 반드시 카드를 제시하여야만 해당 특전을 받을 수 있습니다. (회원 카드 미소지 회원의 경우 회원 여부 확인을 위하여 신분증 제시를 요청 할 수
        있습니다.)
      </li>
      <li>제9조 가입취소는 발급일로부터 30일 이내에만 가능하며, 반드시 회원카드를 반납해야 합니다. 단, 1회이상 사용 또는 발급 후 1개월 이상 경과 시 취소 및 타입변경이
        불가합니다.
      </li>
      <li>제10조 호텔은 회원이 다음 각 항에 해당하는 행위를 할 경우 회원자격을 정지 또는 박탈시킬 수 있습니다.
        <br>1. 가입 신청서를 허위로 기재하였을 경우
        <br>2. 멤버십을 부당한 목적으로 사용하였을 경우
        <br>3. 회원이 본 약관을 위반하였을 경우
      </li>
      <li>제11조 회원의 자격정지 또는 박탈의 경우 기 납입한 연회비는 반환되지 않습니다.</li>
      <li>제12조 호텔은 필요한 경우 멤버십 운영 및 회원 특전 등에 대한 내용을 사전 또는 사후 고지를 통해 변경, 추가 또는 취소 등의 결정을 할 수 있습니다. 또한, 호텔은
        제휴업체의
        일방적인 제휴조건 변경이나 영업폐지, 천재지변, 호텔 경영상황의 급변 내지 경영위기 기타 이에 준하는 사유가 발생한 경우 멤버십 운영 및 회원특전 등에 대한 변경, 추가
        또는 취소 등의
        결정을 할 수 있습니다.
      </li>
    </ul>
    <p class="mems_contact">※ 문의 : 더파르나스팀 02-559-7723, 02-559-7660 / theparnas@parnas.co.kr</p>
  </section>
</template>

<style scoped>

</style>
