import axiosInstance from "@/api/proxyHandler";

export default {
    /**
     * 로그인
     */
    async signIn(model) {
        return await axiosInstance.post(`/auth/login`, {
            ...model,
        });
    },

    /**
     * 로그아웃
     */
    async logout() {
        return await axiosInstance.post(`/members/logout`);
    },


    /**
     * 회원가입 -> 핸드폰 번호 확인 -> 인증번호 전송
     */
    async postSendAuthNumberBySignup(name, phone) {
        return await axiosInstance.post(`/auth/signup/send-auth-number`, {
            name: name,
            phone: phone
        })
    },
    /**
     * 회원가입 -> 핸드폰 번호 확인 -> 인증번호 확인
     */
    async postCheckAuthNumberBySignup(phone, phoneAuthNumber) {
        return await axiosInstance.post(`/auth/signup/check-auth-number`, {
            phone: phone,
            phoneAuthNumber: phoneAuthNumber
        })
    },

    /**
     * 회원가입
     */
    async signUp(model, email) {
        return await axiosInstance.post(`/auth/signup`, {
            ...model,
            email: email
        })
    },

    /**
     * 아이디 중복 체크
     */
    async getMemberIdCheck(email) {
        return await axiosInstance.get(`/auth/id-check`, {
            params: {
                memberEmail: email
            }
        })
    },

    /**
     * 인증번호 전송
     */
    async getPhoneAuthNum(model) {
        return await axiosInstance.post(`/auth/phone-auth`, {
            ...model
        })
    },

    /**
     * 핸드폰 인증번호 확인
     */
    async checkPhoneAuthNum(model) {
        return await axiosInstance.post(`/auth/check-phone-auth`, {
            ...model
        })
    },

    /**
     * 아이디 찾기 -> 핸드폰 인증번호 전송
     */
    async getPhoneAuthNumFindId(model) {
        return await axiosInstance.post(`/auth/find-id/send-auth-number`, {
            ...model
        })
    },

    /**
     * 아이디 찾기 -> 핸드폰 인증번호 확인
     */
    async checkPhoneAuthNumFindId(model) {
        return await axiosInstance.post(`/auth/find-id/check-auth-number`, {
            ...model
        })
    },

    /**
     * 아이디 찾기 -> 회원 정보 보여주기
     */
    async getUserId(model) {
        return await axiosInstance.post(`/auth/find-id`, {
            ...model
        })
    },

    /**
     * 비밀번호 재설정 ->  핸드폰 인증번호 전송
     */
    async getPhoneAuthNumFindPw(model) {
        return await axiosInstance.post(`/auth/patch-password/send-auth-number`, {
            ...model
        })
    },

    /**
     * 비밀번호 재설정 -> 핸드폰 인증번호 확인
     */
    async checkPhoneAuthNumFindPw(model) {
        return await axiosInstance.post(`/auth/patch-password/check-auth-number`, {
            ...model
        })
    },

    /**
     * 비밀번호 재설정 -> 비밀번호 재설정
     */
    async patchPassword(model, password) {
        return await axiosInstance.patch(`/auth/patch-password`, {
            ...model,
            password: password
        })
    },

    /**
     * 마이 페이지 -> 프로필 수정 비밀번호 확인
     */
    async checkPassword(password) {
        return await axiosInstance.post(`/members/check-password`, {
            password: password
        })
    },

    /**
     * 첫 로그인 시 핸드폰 인증 번호 받기
     */
    async phoneSendAuthPhoneCheck(phone) {
      return await axiosInstance.post(`/members/phone-check/send-auth-number`, {
          phone: phone
      })
    },

    /**
     * 첫 로그인 시 핸드폰 인증 번호 확인
     */
    async phoneCheckAuthPhoneCheck(model) {
      return await axiosInstance.patch(`/members/phone-check/check-auth-number`, {
          ...model
      })
    },

    /**
     * 첫 로그인 시, 멤버십이 없는 회원일 경우 멤버십 동의 약관
     */
    async patchMembershipTerms(model) {
      return await axiosInstance.patch(`/members/membership-terms`, {
          ...model
      })
    },

    /**
     * 로그인 한 사람의 정보 가져오기
     */
    async getMemberInfo() {
        return await axiosInstance.get('/members/me')
    },

    /**
     * fcmToken 저장
     */
    async saveFcmToken(fcmToken) {
        return await axiosInstance.post('/members/fcm-token', {
            fcmToken: fcmToken
        })
    },

    /**
     * fcmToken 삭제
     */
    async removeFcmToken() {
        return await axiosInstance.delete('/members/fcm-token')
    },

};
